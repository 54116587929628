<script>
export default {
  methods: {
    getEmissionsByScopeNew (emissionsSources) {
      let emissionsByScope = {
        activities: [],
        scopeOneEmissions: 0,
        scopeOneEmissionsMarketRate: 0,
        scopeTwoEmissions: 0,
        scopeTwoEmissionsMarketRate: 0,
        scopeThreeEmissions: 0,
        scopeThreeEmissionsMarketRate: 0,
        totalEmissions: 0,
        totalEmissionsMarketRate: 0
      }
      emissionsByScope.activities = this.getActivities(emissionsSources)
      emissionsSources.forEach(es => {
        if (es.GHGScope === 1) {
          emissionsByScope.scopeOneEmissions += es.tCO2e
          emissionsByScope.scopeOneEmissionsMarketRate += es.tCO2eMarketRate
        }
        if (es.GHGScope === 2) {
          emissionsByScope.scopeTwoEmissions += es.tCO2e
          emissionsByScope.scopeTwoEmissionsMarketRate += es.tCO2eMarketRate
        }
        if (es.GHGScope === 3) {
          emissionsByScope.scopeThreeEmissions += es.tCO2e
          emissionsByScope.scopeThreeEmissionsMarketRate += es.tCO2eMarketRate
        }
        emissionsByScope.totalEmissions += es.tCO2e
        emissionsByScope.totalEmissionsMarketRate += es.tCO2eMarketRate
        emissionsByScope.totalPreOffsetEmissions = emissionsByScope.totalEmissions - this.getPreOffsetTotal()
      })
      emissionsByScope = this.addSummaryOffsets(emissionsByScope, emissionsSources)
      return emissionsByScope
    },
    addSummaryOffsets (emissionsByScope) {
      // add total per scope x carbon price but check for market rate
      emissionsByScope.scopeOneOffset = emissionsByScope.scopeOneEmissions * this.carbonPrice
      emissionsByScope.scopeTwoOffset = emissionsByScope.scopeTwoEmissions * this.carbonPrice
      emissionsByScope.scopeThreeOffset = emissionsByScope.scopeThreeEmissions * this.carbonPrice
      emissionsByScope.totalOffset = emissionsByScope.totalEmissions * this.carbonPrice
      emissionsByScope.totalPreOffset = (emissionsByScope.totalEmissions - this.getPreOffsetTotal()) * this.carbonPrice
      return emissionsByScope
    },
    getActivities (emissionsSources) {
      let activities = []
      const marketRateExists = this.checkEmissionsSourcesForMarketRate(emissionsSources)
      emissionsSources.forEach(es => {
        let index = activities.findIndex(
          activity => activity.name === es.subcategory
        )
        if (index !== -1) {
          activities[index].tCO2e += es.tCO2eMarketRate
          activities[index].offset += es.tCO2eMarketRate * this.carbonPrice
        } else {
          let activity = {
            scope: es.GHGScope,
            name: es.subcategory,
            tCO2e: es.tCO2eMarketRate,
            offset: es.tCO2eMarketRate * this.carbonPrice
          }
          activities.push(activity)
        }
      })
      // add percentage of inventory
      const totals = this.getTotalsFromEmissionsSources(emissionsSources)
      activities.forEach(activity => {
        activity.percentageOfInventory = this.getPercentage(activity.tCO2e, marketRateExists ? totals.emissionsMR : totals.emissions)
      })
      return activities.sort((a, b) => b.tCO2e - a.tCO2e)
    },

    getAllSchemas (schema) {
      let scopes = ['scopeOneSchemas', 'scopeTwoSchemas', 'scopeThreeSchemas']
      // put all schemas into an array for easy looping
      let allSchemas = []
      scopes.forEach(scope => {
        schema[scope].forEach(scopeSchema => {
          if (scopeSchema.length > 0) {
            scopeSchema.forEach(sectionSchema => {
              allSchemas.push(sectionSchema)
            })
          }
        })
      })
      if (schema.additionalSources) {
        allSchemas.push(schema.additionalSources)
      }
      return allSchemas
    },
    getRawEmissionsSources (emissionsSources) {
      return emissionsSources.map(es => {
        // if es type != custom add E-F properties, else set them to '-'
        es = this.addEmissionsFactorProperties(es)
        // add percentage of inventory
        es = this.addEmissionsPercentageOfInventory(es, emissionsSources)
        return es
      }).sort((a, b) => b.tCO2e - a.tCO2e)
    },
    addEmissionsPercentageOfInventory (es, emissionsSources) {
      const totals = this.getTotalsFromEmissionsSources(emissionsSources)
      es.percentageOfInventory = this.getPercentage(es.tCO2e, totals.emissions)
      es.percentageOfInventoryMR = this.getPercentage(es.tCO2eMarketRate, totals.emissionsMR)
      return es
    },
    addEmissionsFactorProperties (es) {
      if (es.type !== 'custom') {
        es['E.F kgCO2e'] = es.unit === 'tCO2e' ? 1000 : (es.tCO2e * 1000) / es.annualTotal
        es['E.F kgCO2'] = es.CO2 ? (es.CO2 * 1000) / es.annualTotal : 0
        es['E.F kgCH4'] = es.CH4 ? (es.CH4 * 1000) / es.annualTotal : 0
        es['E.F kgN2O'] = es.N2O ? (es.N2O * 1000) / es.annualTotal : 0
      } else {
        // additional sources don't have emissions factors or annual total
        es['Annual Total Input'] = '-'
        es['E.F kgCO2e'] = '-'
        es['E.F kgCO2'] = '-'
        es['E.F kgCH4'] = '-'
        es['E.F kgN2O'] = '-'
      }
      return es
    },
    sanatiseEmissionsSourcesForBreakdown (emissionsSources) {
      const emissionsSourcesBreakdown = []
      emissionsSources.forEach(es => {
        let breakdown = {
          'Business unit': es.businessUnit,
          'GHG Scope': es.GHGScope,
          'GHG sub category': es.GHGSubcategory,
          'ISO category': es.ISOCategory,
          'Emissions source': es.name,
          Unit: es.unit,
          'Annual Total Input': this.$root.formatNumber(es.annualTotal, 2),
          'tCO2e (Output)': es.tCO2e,
          'tCO2e (Market Rate)': es.tCO2eMarketRate,
          'tCO2': es.CO2,
          'tCH4': es.CH4,
          'tN2O': es.N2O,
          'E.F kgCO2e': es['E.F kgCO2e'],
          'E.F kgCO2': es['E.F kgCO2'],
          'E.F kgCH4': es['E.F kgCH4'],
          'E.F kgN2O': es['E.F kgN2O'],
          'percentageOfInventory': es.percentageOfInventory,
          'percentageOfInventoryMR': es.percentageOfInventoryMR
        }
        emissionsSourcesBreakdown.push(breakdown)
      })
      return emissionsSourcesBreakdown.sort((a, b) => b.tCO2e - a.tCO2e)
    },
    getTotalsFromEmissionsSources (emissionsSources) {
      let total = 0
      let totalMR = 0
      emissionsSources.forEach(es => {
        total += es.tCO2e
        totalMR += es.tCO2eMarketRate
      })
      return { emissions: total, emissionsMR: totalMR }
    },
    getPercentage (tCO2e, total) {
      return ((tCO2e / total) * 100).toFixed(2) + '%'
    },
    getAllEmissionSources (emissionsSources) {
      let formattedEmissionsSources = []
      let marketRateExists = this.checkEmissionsSourcesForMarketRate(emissionsSources)
      // Exclude WTT and TDL emissions
      const emissionsSourcesNoFuelEnergy = emissionsSources.filter(es => es.type !== 'WTT' && es.type !== 'naturalGasTDL' && es.type !== 'electricityTDL')
      emissionsSourcesNoFuelEnergy.forEach(emissionSource => {
        let emissionBreakdown = {
          'GHG Scope': emissionSource.GHGScope,
          'GHG sub category': emissionSource.GHGSubcategory,
          'ISO category': emissionSource.ISOCategory,
          'Emissions source': emissionSource.name,
          Unit: emissionSource.unit,
          'Annual Total Input': this.$root.formatNumber(
            emissionSource.annualTotal,
            0
          ),
          'tCO2e (Output)': emissionSource.tCO2e,
          'tCO2e (Market Rate)':
            'tCO2eMarketRate' in emissionSource && marketRateExists ? emissionSource.tCO2eMarketRate : emissionSource.tCO2e // was N/A
        }
        let percentageOfInventory =
          this.$root.formatNumber((emissionBreakdown['tCO2e (Output)'] /
            this.getTotalsFromEmissionsSources(emissionsSources).emissions) *
            100)

        let percentageOfInventoryMR =
          this.$root.formatNumber((emissionBreakdown['tCO2e (Market Rate)'] /
            this.getTotalsFromEmissionsSources(emissionsSources).emissionsMR) *
            100)
        emissionBreakdown.percentageOfInventory = percentageOfInventory + '%'
        emissionBreakdown.percentageOfInventoryMR = percentageOfInventoryMR + '%'
        formattedEmissionsSources.push(emissionBreakdown)
      })
      // add WTT and TDL emissions sources
      const fuelEnergyEmissionsSources = this.addFuelEnergyEmissionsSources(emissionsSources)
      formattedEmissionsSources.push(...fuelEnergyEmissionsSources)
      return formattedEmissionsSources.sort(
        (a, b) => b['tCO2e (Market Rate)'] - a['tCO2e (Market Rate)']
      )
    },
    addFuelEnergyEmissionsSources (emissionsSources) {
      let fuelEnergyEmissionsSources = []
      // well to tank
      let wellToTank = this.getWTTandTDL(
        this.calculateWellToTank(emissionsSources),
        'Well to tank emissions',
        emissionsSources
      )
      // electricity TDL
      let electricityTDL = this.getWTTandTDL(
        this.calculateElectricityTDL(emissionsSources),
        'Electricity T&D Losses',
        emissionsSources
      )
      // naturalGasTDL
      let naturalGasTDL = this.getWTTandTDL(
        this.calculateNaturalGasTDL(emissionsSources),
        'Natural Gas T&D Losses',
        emissionsSources
      )

      if (wellToTank) {
        fuelEnergyEmissionsSources.push(wellToTank)
      }
      if (electricityTDL) {
        fuelEnergyEmissionsSources.push(electricityTDL)
      }
      if (naturalGasTDL) {
        fuelEnergyEmissionsSources.push(naturalGasTDL)
      }
      return fuelEnergyEmissionsSources
    },
    checkEmissionsSourcesForMarketRate (emissionsSources) {
      let marketRateExists = false
      emissionsSources.forEach(es => {
        if (es.tCO2eMarketRate !== es.tCO2e) {
          marketRateExists = true
        }
      })
      return marketRateExists
    },
    getEmissionsByActivity (emissionsSources) {
      var activities = []
      emissionsSources.forEach(es => {
        // console.log(es)
        let index = activities.findIndex(
          activity => activity.name === es.subcategory
        )
        if (index !== -1) {
          // console.log(es.tCO2e, 'found index')
          activities[index].tCO2e += es.tCO2e
          activities[index].tCO2eMarketRate += es.tCO2eMarketRate ?? es.tCO2e
          activities[index].offset += es.tCO2e * this.carbonPrice
        } else {
          let activity = {
            scope: es.GHGScope,
            name: es.subcategory,
            tCO2e: es.tCO2e,
            tCO2eMarketRate: es.tCO2eMarketRate ?? es.tCO2e,
            offset: es.tCO2e * this.carbonPrice
          }
          activities.push(activity)
        }
      })
      return activities
    },
    getWTTandTDL (emission, name, emissionsSources) {
      if (emission.tCO2e !== 0) {
        let emissionSource = {
          'GHG Scope': 3,
          'GHG sub category': 4,
          'ISO category': 3,
          'Emissions source': name,
          Unit: 'KG',
          'Annual Total Input': this.$root.formatNumber(
            emission.annualTotal,
            0
          ),
          percentageOfInventory: this.$root.formatNumber((emission.tCO2e / this.getTotalsFromEmissionsSources(emissionsSources).emissions) * 100) + '%',
          percentageOfInventoryMR: this.$root.formatNumber((emission.tCO2e / this.getTotalsFromEmissionsSources(emissionsSources).emissionsMR) * 100) + '%',
          'tCO2e (Output)': emission.tCO2e,
          'tCO2e (Market Rate)': emission.tCO2e
        }
        return emissionSource
      } else {
        return false
      }
    },
    calculateNaturalGasTDL (allSources) {
      let total = 0
      let tCO2e = 0
      const sources = allSources.filter(es => es.type === 'naturalGasTDL')
      sources.forEach(source => {
        total += source.annualTotal
        tCO2e += source.tCO2e
      })
      return { annualTotal: total, tCO2e: tCO2e }
    },
    calculateElectricityTDL (allSources) {
      let total = 0
      let tCO2e = 0
      const sources = allSources.filter(es => es.type === 'electricityTDL')
      sources.forEach(source => {
        total += source.annualTotal
        tCO2e += source.tCO2e
      })
      return { annualTotal: total, tCO2e: tCO2e }
    },
    calculateWellToTank (allSources) {
      let total = 0
      let tCO2e = 0
      const sources = allSources.filter(es => es.type === 'WTT')
      sources.forEach(source => {
        total += source.annualTotal
        tCO2e += source.tCO2e
      })
      return { annualTotal: total, tCO2e: tCO2e }
    },
    getAllActiveSchemas (schema) {
      let activeSchemas = []
      let allSchemas = this.getAllSchemas(schema)
      allSchemas.forEach((schema, index) => {
        let active = false
        schema.emissionSources.forEach(es => {
          if (es.tCO2e !== 0 && (es.annualTotal !== 0 || es.type === 'custom')) {
            active = true
          }
        })
        if (active) {
          activeSchemas.push(schema)
        }
      })
      return activeSchemas
    },
    getPreOffsetTotal () {
      const offsets = this.$store.state.app.report.recognisedOffsetDeductions.nodes
      if (!offsets.length) {
        return this.$store.state.app.report.initialCarbonOffset ? this.$store.state.app.report.initialCarbonOffset : 0
      }
      let total = 0
      offsets.forEach(offset => {
        total += offset.amount
      })
      return total
    },
    getTotalOffset () {
      return this.$store.state.app.report.GHGRemoval + this.getPreOffsetTotal()
    },
    getCreditsToOffset (emissionsSources, multiple) {
      let totals = this.getTotalsFromEmissionsSources(emissionsSources)
      return Math.ceil(this.checkEmissionsSourcesForMarketRate(emissionsSources) ? (totals.emissionsMR * multiple) - this.getTotalOffset() : (totals.emissions * multiple) - this.getTotalOffset())
    },
    getElectricityOffset (schema) {
      let total = 0
      let offsetUnits = []
      const allActiveSchemas = this.getAllActiveSchemas(schema)
      const electricitySchemas = allActiveSchemas.filter(schema =>
        schema.name.includes('Electricity')
      )
      electricitySchemas.forEach(schema => {
        if (schema.units !== undefined) {
          schema.units.forEach(unit => {
            if (!unit.hash) return
            // carbon neutral offset both carbon emission and TDL
            if (unit.carbonNeutral) {
              let offset = this.addElectricityOffset(
                unit,
                // market or location based
                unit.emissions.carbonEmissionsMR,
                schema.scope,
                schema.emissionSources[0].subcategory,
                'Carbon Neutral Electricity',
                unit.hash,
                'Ecotricity'
              )
              // add TDL offset here
              let offsetTDL = this.addElectricityOffset(
                unit,
                // market or location based
                unit.emissions.TDLEmissions.CO2e,
                schema.scope,
                schema.emissionSources[0].subcategory,
                'Carbon neutral electricity T&D losses',
                unit.hash + 'tdl',
                'Ecotricity'
              )
              total += offset.amount
              total += offsetTDL.amount
              offsetUnits.push(offsetTDL)
              offsetUnits.push(offset)
            } else if (unit.certifiedRenewable) {
              let offsetRec = this.addElectricityOffset(
                unit,
                // market or location based
                unit.emissions.carbonEmissionsMR,
                schema.scope,
                schema.emissionSources[0].subcategory,
                'REC Electricity',
                unit.hash,
                'Meridian REC'
              )
              // add TDL offset here
              let offsetRecTDL = this.addElectricityOffset(
                unit,
                // market or location based
                unit.emissions.TDLEmissions.CO2e,
                schema.scope,
                schema.emissionSources[0].subcategory,
                'REC Electricity T&D losses',
                unit.hash + 'tdl',
                'Meridian REC'
              )
              total += offsetRec.amount
              total += offsetRecTDL.amount
              offsetUnits.push(offsetRec)
              offsetUnits.push(offsetRecTDL)
            }
          })
        }
      })
      const calcRODs = this.$store.state.app.report.recognisedOffsetDeductions.nodes.filter(
        offset => offset.type === 'Calculated'
      )
      // if (calcRODs.length > offsetUnits.length) {
      this.removeCalculatedOffsets(calcRODs, offsetUnits)
      // }
      return { units: offsetUnits, total: total }
    },
    addElectricityOffset (unit, emissions, scope, subcategory, title, hash, notes) {
      // let name =
      //   unit.selectedCountry === 'Other' ? unit.country : unit.selectedCountry

      let offset = {
        hash: hash,
        scope: scope,
        subcategory: subcategory,
        title: title,
        amount: emissions,
        notes: notes
      }
      let offsetExists = this.$store.state.app.report.recognisedOffsetDeductions.nodes.find(
        rod => rod.hash === offset.hash
      )
      if (!offsetExists) {
        // create offset
        // console.log('creating')
        this.createRecognisedOffsetDeduction(offset)
      } else if (this.$root.formatNumber(offsetExists.amount) !== this.$root.formatNumber(offset.amount)) {
        // update offset
        offset.id = parseInt(offsetExists.id)
        this.updateRecognisedOffsetDeduction('amount', offset)
      }
      return offset
    },
    removeCalculatedOffsets (savedOffsets, currentOffsets) {
      savedOffsets.forEach(savedOffset => {
        let foundOffset = currentOffsets.find(currentOffset => currentOffset.hash === savedOffset.hash)
        if (!foundOffset) {
          this.removeRecognisedOffsetDeduction(savedOffset)
        }
      })
    },
    createRecognisedOffsetDeduction (offset) {
      const data = {
        recognisedOffsetDeduction: {
          reportID: this.$store.state.app.report.id,
          hash: offset.hash,
          title: offset.title,
          notes: offset.notes,
          amount: offset.amount
        }
      }
      this.$root.saving = true
      let res = this.$store.dispatch('createRecognisedOffsetDeduction', data)
      setTimeout(() => {
        this.$root.saving = false
      }, 2000)
      return res
    },
    updateRecognisedOffsetDeduction (field, offset) {
      const data = { recognisedOffsetDeduction: { id: offset.id } }
      data.recognisedOffsetDeduction[field] = offset[field]
      // console.log(data.otherEmissions, 'updating otherEmissions data')
      this.$root.saving = true
      this.$store.dispatch('updateRecognisedOffsetDeduction', data)
      setTimeout(() => {
        this.$root.saving = false
      }, 2000)
    },
    removeRecognisedOffsetDeduction (offset) {
      const data = [offset.id]
      this.$root.saving = true
      this.$store.dispatch('deleteRecognisedOffsetDeduction', data)
      setTimeout(() => {
        this.$root.saving = false
      }, 2000)
    }
  }
}
</script>

<style></style>
